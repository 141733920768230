







// <p :class="classes" :style="style" role="text"><template v-if="original === false">{{ text }}</template><slot v-else></slot></p>
  import { Vue, Component, Prop } from 'vue-property-decorator'
  import { AppModule } from '@/store/module'

  @Component({
    inheritAttrs: false,
  })
  export default class VText extends Vue {
    text = ''

    $el!: HTMLDivElement

    /** 加粗字体 */
    @Prop({ default: false })
    bold: boolean

    /** 不处理文本 */
    @Prop({ default: false })
    original: boolean

    /** tag */
    @Prop({ default: 'p' })
    tag: string

    get classes() {
      return [
        'text',
        {
          'text-bold': this.bold !== false,
        },
      ]
    }

    get style() {
      const style = {}
      for (const key in this.$attrs) {
        const arr = this.$attrs[key].split('|')
        if (arr.length === 1 || !AppModule.isH5) {
          style[key] = this.px2rem(arr[0])
        } else {
          style[key] = this.px2rem(arr[1])
        }
      }
      return style
    }

    px2rem(val) {
      if (val.indexOf('px') === -1) return val
      return (parseFloat(val) / process.env.VUE_APP_ROOT_VALUE) + 'rem'
    }

    created() {
      this.text = (this.$slots.default && this.$slots.default[0].text?.trim()) || ''
    }

    get content () {
      return this.tag || 'p'
    }
  }
