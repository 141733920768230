












































































import { Vue, Component, Prop } from 'vue-property-decorator'
import { AppModule } from '@/store/module'

@Component({
  inheritAttrs: false,
})
export default class DropdownContentList extends Vue {

  @Prop({
    type: String
  })
  title: string

  @Prop({
    type: Array,
    default() {
      return []
    }
  })
  list: []

  get classes() {
    return [
      'dropdown-content-list'
    ]
  }

  get style() {
    const style = {}
    for (const key in this.$attrs) {
      const arr = this.$attrs[key].split('|')
      if (arr.length === 1 || !AppModule.isH5) {
        style[key] = this.px2rem(arr[0])
      } else {
        style[key] = this.px2rem(arr[1])
      }
    }
    return style
  }

  px2rem(val) {
    if (val.indexOf('px') === -1) return val
    return (parseFloat(val) / process.env.VUE_APP_ROOT_VALUE) + 'rem'
  }

  onMouseOver(item) {
    item.hover = true
  }

  onMouseOut(item) {
    item.hover = false
  }
}
