






import { Vue, Component, Prop } from 'vue-property-decorator'
import { AppModule } from '@/store/module'

@Component({
  inheritAttrs: false,
})
export default class Block extends Vue {
  /** 背景图：url 或者 pc|h5 */
  @Prop(String)
  backgroundImage: string

  /** 是否橫向佈局，默认为竖向 */
  @Prop({ type: Boolean, default: false })
  row!: boolean

  get classes() {
    return ['block', {
      'block-row': this.row !== false
    }]
  }

  get style() {
    const style = {}
    for (const key in this.$attrs) {
      const arr = this.$attrs[key].split('|')
      if (arr.length === 1 || !AppModule.isH5) {
        style[key] = this.px2rem(arr[0])
      } else {
        style[key] = this.px2rem(arr[1])
      }
    }
    if (this.bgImageUrl) {
      style['background-image'] = `url(${this.bgImageUrl})`
      style['background-position'] = 'center center'
      style['background-size'] = 'cover'
      style['background-repeat'] = 'no-repeat'
    }
    return style
  }

  get bgImageUrl() {
    if (!this.backgroundImage) return ''
    const arr = this.backgroundImage.split('|')
    if (arr.length === 1 || !AppModule.isH5) {
      return arr[0]
    } else {
      return arr[1]
    }
  }

  px2rem(val) {
    if (val.indexOf('px') === -1) return val
    return (parseFloat(val) / process.env.VUE_APP_ROOT_VALUE) + 'rem'
  }
}
