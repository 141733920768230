import Vue from 'vue'
import Btn from './Btn.vue'
import Check from './Check.vue'
import Icon from './Icon.vue'
import VText from './VText.vue'
import Block from './Block.vue'
import QnImage from './QnImage.vue'
import Dropdown from './Dropdown.vue'
import Swiper from './Swiper.vue'
import SwiperSlide from './SwiperSlide.vue'
import Modal from './Modal.vue'

// 全局的基础组件
Vue.component('Btn', Btn)
Vue.component('Check', Check)
Vue.component('Icon', Icon)
Vue.component('vText', VText)
Vue.component('Block', Block)
Vue.component('QnImage', QnImage)
Vue.component('Dropdown', Dropdown)
Vue.component('Swiper', Swiper)
Vue.component('SwiperSlide', SwiperSlide)
Vue.component('Modal', Modal)
