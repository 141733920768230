






import { Vue, Component, Prop } from 'vue-property-decorator'
import { AppModule } from '@/store/module'

@Component
export default class SwiperSlide extends Vue {
  inited = false

  mounted() {
    // 保证父组件su-swiper初始化完成后才初始化swiper-slide的slot内容
    // 确保slot内容初始化的时候能够准确得到slide的宽度等信息
    // 移动端应用需求，PC因为绝大多数布局都可以用绝对单位，所以不需要做此处理
    this.inited = true
  }
}
