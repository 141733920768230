import Vue from 'vue'

interface Href {
  href(string): void;
  open(string): void;
}

declare module 'vue/types/vue' {
  interface Vue {
    $href: Href;
  }
}

function href(url) {
  window.location.href = url
}

function open(url) {
  window.open(url)
}

const Href = {
  install(Vue, options) {
    Vue.prototype.$href = {
      href,
      open
    }

  }
}

export default Href