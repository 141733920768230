









import { Vue, Component, Prop, Watch } from 'vue-property-decorator'

// 七牛图片基本处理，参考链接：https://developer.qiniu.com/dora/manual/1279/basic-processing-images-imageview2
@Component({})
export default class QnImage extends Vue {
  imgUrl = ''
  wrapWidth = 0
  wrapHeight = 0
  DPR = window.devicePixelRatio || 1

  @Prop({ required: true })
  src: string

  @Prop(String)
  alt: string

  /**
   * 图片展示类型
   * bgImg: 背景图
   * img: <img/>标签
   */
  @Prop({
    default: 'bgImg',
    validator: val => ['bgImg', 'img'].includes(val),
  })
  type: string

  @Prop({ default: '100%' })
  width: string | number

  @Prop({ default: '100%' })
  height: string | number

  /** 七牛参数：裁切方式 */
  @Prop({ default: 1 })
  clippingType: number

  /** 七牛参数：新图的输出格式 */
  @Prop({ default: '' })
  format: string

  /** 是否原图 */
  @Prop({ default: false })
  original: boolean

  @Prop({
    default: 'https://spics.wantu.cn/7de3bb719f90f1b80a0b4995d69df0ca.png',
  })
  placeholderSrc: string

  get wrapStyles() {
    console.log('-----:', this.width)
    return {
      width: typeof this.width === 'number' ? this.px2rem(`${this.width}px`) : void 0,
      height: typeof this.height === 'number' ? this.px2rem(`${this.height}px`) : void 0,
    }
  }

  get formatWidth() {
    const width = typeof this.width === 'number' ? this.width : this.wrapWidth
    return width ? `/w/${Math.ceil(width * this.DPR)}` : ''
  }

  get formatHeight() {
    const height = typeof this.height === 'number' ? this.height : this.wrapHeight
    return height ? `/h/${Math.ceil(height * this.DPR)}` : ''
  }

  get formatFormat() {
    return `/format/${this.format}`
  }

  get qiniuFormat() {
    return '?imageView2/' + this.clippingType + this.formatWidth + this.formatHeight + this.formatFormat
  }

  @Watch('src')
  onSrcChanged() {
    this.getImageURL()
  }

  mounted() {
    this.wrapWidth = (this.$refs.wrap as HTMLDivElement).offsetWidth
    this.wrapHeight = (this.$refs.wrap as HTMLDivElement).offsetHeight
    this.getImageURL()
  }

  /** 得到图片的 url */
  getImageURL() {
    this.imgUrl = this.src ? this.src + (!this.original ? this.qiniuFormat : '') : this.placeholderSrc
  }

  px2rem(val) {
    if (val.indexOf('px') === -1) return val
    return (parseFloat(val) / process.env.VUE_APP_ROOT_VALUE) + 'rem'
  }
}
