































































import { Component, Prop, Vue } from 'vue-property-decorator'
import { AppModule } from '@/store/module'
import DemoShowCardLarge from '@/views/others/components/DemoShowCardLarge.vue'
import DemoShowCard from '@/views/others/components/DemoShowCard.vue'

@Component({
  components: {
    DemoShowCardLarge,
    DemoShowCard
  }
})
export default class DemoShowModal extends Vue {
  @Prop(Boolean)
  isShow: boolean

  get demos() {
    return AppModule.demos
  }

  get isH5() {
    return AppModule.isH5
  }
}
