
































import { Component, Prop, Vue } from 'vue-property-decorator'
import { AppModule } from '@/store/module'
import ContactForm from '@/components/ContactForm.vue'

@Component({
  components: {
    ContactForm
  }
})
export default class RegisterModal extends Vue {
  @Prop(Boolean)
  isShow: boolean

  get isH5() {
    return AppModule.isH5
  }
}
