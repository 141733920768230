



























import { Component, Prop, Vue } from 'vue-property-decorator'
import { AppModule } from '@/store/module'

@Component
export default class RestaurantCard extends Vue {
  @Prop(String)
  name: string

  @Prop(String)
  storeName: string

  @Prop(String)
  comment: string

  @Prop(String)
  img: string

  get isH5() {
    return AppModule.isH5
  }
}
