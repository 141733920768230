
























import { Vue, Component, Prop } from 'vue-property-decorator'
import { AppModule } from '@/store/module'

@Component({
  model: {
    prop: 'show',
    event: 'switch'
  }
})
export default class Modal extends Vue {

  transitionOptions = {
    'from-center': 'fade',
    'from-top': 'slide-down',
    'from-bottom': 'slide-up',
    'from-left': 'slide-left',
    'from-right': 'slide-right'
  }

  get isH5() {
    return AppModule.isH5
  }

  @Prop({
    type: Boolean,
    default: false
  })
  show: boolean

  @Prop(String)
  maskTransition: string

  @Prop({
    type: String,
    default: 'from-center'
  })
  type: string

  @Prop({
    type: String,
    default: '#fff'
  })
  background: string

  @Prop({
    type: String,
    default: 'rgba(0, 0, 0, .75)'
  })
  modalBackground: string

  @Prop({
    type: Boolean,
    default: true
  })
  maskClosable: boolean    

  closeModal() {
    this.$emit('switch', false)
    this.$emit('close-modal')
  }

  noop() {
    return
  }
}
