/* eslint-disable @typescript-eslint/camelcase */
import { PluginObject } from 'vue'
const _w = window
const _d = document
declare global {
  interface HTMLElement {
    init_margin?: string;
    init_padding?: string;
    el_placeholder?: HTMLElement;
    reset_style?: string;
    parent_backup?: Node;
  }
}
declare module 'vue/types' {
  interface VNode {
    fixedHandler(event: Event): void;
  }
}
const _fixed_base_style = {
  position: 'fixed',
  zIndex: '99',
  top: '0',
  left: '0',
  margin: '0',
  width: '100%'
}

const getResetStyle = function (el) {
  const reset_style = {}
  const style = _w.getComputedStyle(el)
  for (const key in _fixed_base_style) {
    reset_style[key] = style[key]
  }
  return reset_style
}

const Fixed: PluginObject<never> = {
  install(Vue, options) {
    // v-fixed
    Vue.directive('fixed', {
      // bind event
      bind: function (el, binding, vnode, oldVnode) {
        let el_rect;
        let apply_style;
        let is_fixed;
        vnode.fixedHandler = function (event) {
          el_rect = el.getBoundingClientRect();

          // 目标元素已隐藏 (ps: 针对v-show)
          if (!el_rect.width && !el_rect.height) return;

          // 元素是否处于fixed状态
          is_fixed = el.el_placeholder.getBoundingClientRect().top + (parseFloat(el.style.marginTop) || 0) < 0

          apply_style = is_fixed ? Object.assign({}, _fixed_base_style) : Object.assign({}, el.reset_style && JSON.parse(el.reset_style) || {}, {
            width: '100%'
          })
          for (const key in apply_style) {
            el.style[key] = apply_style[key]
          }

          if (el.el_placeholder) {
            el.el_placeholder.style.width = is_fixed ? el_rect.width + 'px' : 0;
            el.el_placeholder.style.height = is_fixed ? el_rect.height + 'px' : 0;
            el.el_placeholder.style.margin = is_fixed ? el.el_placeholder.init_margin : 0
            el.el_placeholder.style.padding = is_fixed ? el.el_placeholder.init_padding : 0
          }
        }
        _w.addEventListener('scroll', vnode.fixedHandler)
      },
      // insert placeholder
      inserted: function (el, binding, vnode, oldVnode) {
        const style = _w.getComputedStyle(el)
        const _rect = el.getBoundingClientRect()
        const placeholder_base_style = ['display', 'margin', 'position', 'top', 'right', 'bottom', 'left']
        const is_fixed = _rect.top < 0
        const el_placeholder = _d.createElement(el.nodeName)

        placeholder_base_style.forEach(key => {
          el_placeholder.style[key] = style[key]
        })

        el_placeholder.style.width = is_fixed ? _rect.width + 'px' : '0'
        el_placeholder.style.height = is_fixed ? _rect.height + 'px' : '0'
        el_placeholder.style.margin = is_fixed ? el_placeholder.style.margin : '0'
        el_placeholder.style.padding = is_fixed ? el_placeholder.style.padding : '0'
        el_placeholder.init_margin = style.margin
        el_placeholder.init_padding = style.padding

        el.el_placeholder = el_placeholder
        el.reset_style = JSON.stringify(getResetStyle(el))

        // insert placeholder to parentNode
        el.parentNode.insertBefore(el.el_placeholder, el)

        // 临时存储父节点，供后续删除placeholder节点
        el.parent_backup = el.parentNode
      },
      unbind: function (el, binding, vnode, oldVnode) {
        // 解绑事件
        _w.removeEventListener('scroll', vnode.fixedHandler)

        // 删除placehandler
        el.parent_backup && el.parent_backup.removeChild(el.el_placeholder)
      }
    })
  }
};
export default Fixed
