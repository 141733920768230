








import { Vue, Component, Prop } from 'vue-property-decorator'
import { AppModule } from '@/store/module'

export type BtnTheme = 'default'
export type BtnSize = 'small' | 'middle' | 'big'

@Component({
  inheritAttrs: false,
})
export default class Btn extends Vue {
  /** 是否缩小字号 */
  smallText = false

  /** 主题 */
  @Prop({
    default: 'default',
    validator: val => ['default', 'link'].includes(val),
  })
  theme: BtnTheme

  /** 尺寸 */
  @Prop({
    default: 'middle',
    validator: val => ['small', 'middle', 'big'].includes(val),
  })
  size: BtnSize

  /** 加粗字体 */
  @Prop({ default: false })
  bold: boolean

  /** 是否禁用状态 */
  @Prop({ default: false })
  disabled: boolean

  /** 行内块 */
  @Prop({ default: false })
  inline: boolean

  /** 前置图标 */
  @Prop(String)
  prefixIcon: string

  /** 后置图标 */
  @Prop(String)
  suffixIcon: string

  /** 文字颜色 */
  @Prop(String)
  textColor: string

  get classes() {
    return [
      'btn',
      [`btn-${this.theme}`],
      [`btn-${this.size}`],
      {
        'btn-disabled': this.btnDisabled,
        'btn-bold': this.bold !== false,
        'btn-inline': this.inline !== false,
      },
    ]
  }

  get style() {
    const style = {}
    for (const key in this.$attrs) {
      const arr = this.$attrs[key].split('|')
      if (arr.length === 1 || !AppModule.isH5) {
        style[key] = this.px2rem(arr[0])
      } else {
        style[key] = this.px2rem(arr[1])
      }
    }
    return style
  }

  get btnDisabled() {
    return this.disabled
  }

  px2rem(val) {
    if (val.indexOf('px') === -1) return val
    return (parseFloat(val) / process.env.VUE_APP_ROOT_VALUE) + 'rem'
  }

  clickHandle(e) {
    if (this.btnDisabled) {
      return
    }

    this.$emit('click', e)
  }
}
