
















import { Vue, Component, Prop } from 'vue-property-decorator'
import { AppModule } from '@/store/module'
import SwiperClass, { Pagination, Autoplay } from 'swiper'
import { AutoplayOptions } from 'swiper/types/components/autoplay'
import { PaginationOptions } from 'swiper/types/components/pagination'
import 'swiper/swiper-bundle.css'
import RestaurantCard from '@/views/home/components/RestaurantCard.vue'

SwiperClass.use([Pagination, Autoplay])

@Component({
  components: {
    RestaurantCard
  }
})
export default class Swiper extends Vue {
  swiper = null

  /** Number of slides per view (slides visible at the same time on slider's container). */
  @Prop({
    type: [Number, String],
    default: 1
  })
  slidesPerView: number | 'auto'

  /** Distance between slides in px */
  @Prop({
    type: Number,
    default: 0
  })
  spaceBetween: number

  /** Add (in px) additional slide offset in the beginning of the container (before all slides */
  @Prop({
    type: Number,
    default: 0
  })
  slidesOffsetBefore: number

  /** Add (in px) additional slide offset in the end of the container (after all slides) */
  @Prop({
    type: Number,
    default: 0
  })
  slidesOffsetAfter: number

  /** 
   * Set to true to enable continuous loop mode 
   * Because of nature of how the loop mode works, it will add duplicated slides.
   */
  @Prop({
    type: Boolean,
    default: false
  })
  loop: boolean

  /**
   * Object with autoplay parameters or boolean true to enable with default settings
   */
  @Prop({
    type: [Boolean, Object],
    default() {
      return {
        delay: 2500,
        disableOnInteraction: false
      }
    }
  })
  autoplay: AutoplayOptions

  /**
   * Object with pagination parameters or boolean true to enable with default settings.
   */
  @Prop({
    type: [Boolean, Object],
    default() {
      return {
        clickable: true
      }
    }
  })
  pagination: PaginationOptions

  mounted() {
    this.init()
  }

  init() {
    this.$nextTick(() => {
      const { slidesPerView, spaceBetween, slidesOffsetBefore, slidesOffsetAfter, loop, autoplay, pagination } = this
      this.swiper = new SwiperClass(this.$refs.swiper as HTMLElement, {
        slidesPerView,
        spaceBetween,
        slidesOffsetBefore,
        slidesOffsetAfter,
        loop,
        autoplay,
        pagination: typeof pagination === 'object' ? {
          el: this.$refs.pagination as HTMLElement,
          ...pagination
        } : pagination
      })
      this.swiper.on('slideChangeTransitionEnd', this.changeIndex)
    })
  }

  changeIndex(swiper: SwiperClass) {
    console.log('active index', swiper.activeIndex, 'real index', swiper.realIndex)
    this.$emit('slide-change', swiper.activeIndex)
  }
}
