


















import { Vue, Component, Prop } from 'vue-property-decorator'
import { AppModule } from '@/store/module'
import DropdownContentList from '@/components/global/DropdownContentList.vue'

@Component({
  inheritAttrs: false,
  components: {
    DropdownContentList
  }
})
export default class Dropdown extends Vue {

  /** 文字颜色 */
  @Prop({
    type: String,
    default: '#fff'
  })
  textColor: string

  /** icon size */
  @Prop({
    type: [String, Number],
    default: '12px'
  })
  iconSize: string | number

  /** 下拉列表 */
  @Prop({
    type: Array,
    default() {
      return []
    }
  })
  list: []

  get classes() {
    return [
      'dropdown'
    ]
  }

  get title() {
    return this.$slots.default[0].text
  }

  get style() {
    const style = {}
    for (const key in this.$attrs) {
      const arr = this.$attrs[key].split('|')
      if (arr.length === 1 || !AppModule.isH5) {
        style[key] = this.px2rem(arr[0])
      } else {
        style[key] = this.px2rem(arr[1])
      }
    }
    return style
  }

  px2rem(val) {
    if (val.indexOf('px') === -1) return val
    return (parseFloat(val) / process.env.VUE_APP_ROOT_VALUE) + 'rem'
  }

}
