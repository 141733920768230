import { Module, VuexModule, getModule, Mutation, Action } from 'vuex-module-decorators'
import store from '@/store'

@Module({ dynamic: true, store, name: 'app' })
class App extends VuexModule {
  isH5 = false

  demos = [
    {
      img: 'https://spics.wantu.cn/21f8e5381ca0fdb5af3eedb7ed12ef92.png',
      name: '咖啡廳Demo',
      id: '4934',
      qrcodeImg: 'https://spics.wantu.cn/89da37c273d42ce11bc86c871de91b52.png'
    },
    {
      img: 'https://spics.wantu.cn/a9ee9be0860f69ff3728ee4ea740408e.png',
      name: '茶餐廳Demo',
      id: '4937',
      qrcodeImg: 'https://spics.wantu.cn/3fe64342c36ddabf359912fb71ac0a8e.png'
    },
    {
      img: 'https://spics.wantu.cn/a29d514c09206650ee903261767f4bdf.png',
      name: '米線店Demo',
      id: '4938',
      qrcodeImg: 'https://spics.wantu.cn/46d5d0e97d3581e5b5a1a8b7a0a64167.png'
    }
  ]

  @Mutation
  setIsH5(bool) {
    this.isH5 = bool
  }

}

export const AppModule = getModule(App)
