





















import { Vue, Component, Prop } from 'vue-property-decorator'

type CheckType = 'checked' | 'circle'

@Component({})
export default class Check extends Vue {
  @Prop({ default: true })
  value: boolean

  @Prop({ default: 16 })
  size: number

  @Prop({ default: 'checked' })
  type: CheckType

  get checkStyles() {
    return {
      width: this.px2rem(typeof this.size === 'number' ? `${this.size}px` : this.size),
      height: this.px2rem(typeof this.size === 'number' ? `${this.size}px` : this.size),
    }
  }

  px2rem(val) {
    if (!val || !val.includes('px')) return val
    return (parseFloat(val) / process.env.VUE_APP_ROOT_VALUE) + 'rem'
  }
}
